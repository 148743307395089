import React, { Component, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../assets/images/logo.png";
import imgbottom from "../assets/images/post/img-bottom.svg";
import imgtop from "../assets/images/post/img-top.svg";
import "../assets/scss/home/home.css";
import "../assets/scss/portofolio/portofolio.css";
import withRouter from "../helpers/API_Portofolio";
import Nav from "../parts/Nav";
import imgPdf from "../assets/images/download/img-pdf.png";
import imgPng from "../assets/images/download/img-png.png";
import imgZip from "../assets/images/download/img-zip.png";
import imgPpt from "../assets/images/download/img-ppt.png";
import iconDownload from "../assets/images/download/icon-download.svg";
import iconDrive from "../assets/images/download/icon-drive.svg";
import { apiUrl, dataUrl } from "../actions/index";
import ReactPaginate from "react-paginate";


export default function MoreDownload() {
  const [download, setDownload] = useState([]);
  const [listPage, setPage] = useState([]);
  const [onGetData, setLoading] = useState(true);

  const [filter, setFilter] = useState({
    currentPage: 1,
  });
  const fetchData = () => {
    fetch(`${apiUrl.download()}&page=1`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDownload(data.list.data);
        setPage(data.list);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const filterData = async (props) => {
    fetch(`${apiUrl.download()}&page=${props.currentPage}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDownload(data.list.data);
        setPage(data.list);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    filter.currentPage = currentPage;
    setFilter(filter);
    filterData(filter);
  };
  // console.log(setNumberPage);
  const getExtention = (jenisFile, file) => {
    if (jenisFile == "drive") {
      return `${dataUrl.download()}icon/drive.png`;
    } else {
      var split = file.split(".");
      var extension = split[split.length - 1];
      if (extension == "png") {
        return `${dataUrl.download()}icon/img-png.png`;
      } else if (extension == "jpg") {
        return `${dataUrl.download()}icon/img-png.png`;
      } else if (extension == "ppt") {
        return `${dataUrl.download()}icon/img-ppt.png`;
      } else if (extension == "zip") {
        return `${dataUrl.download()}icon/img-zip.png`;
      } else if (extension == "pdf") {
        return `${dataUrl.download()}icon/img-pdf.png`;
      } else if (extension == "doc") {
        return `${dataUrl.download()}icon/img-doc.png`;
      } else if (jenisFile == "file") {
        return `${dataUrl.download()}icon/other_file.png`;
      }
    }
  };

  const showData = () => {
    return (
      <>
        {download.map((item, index) => (
          <a
            href={`${item.jenis_file == "drive" ? "" : dataUrl.download()}${
              item.file
            }`}
            target="_blank"
            download
            key={`item-${index}`}>
            <div className="item">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={getExtention(item.jenis_file, item.file)}
                    className="img-file"></img>
                  <div className="title-item">{item.judul}</div>
                </div>
                <div className="icon">
                  <img
                    src={item.jenis_file == "drive" ? iconDrive : iconDownload}
                    className="icon-download"></img>
                </div>
              </div>
            </div>
          </a>
        ))}
      </>
    );
  };
  const showSkeleton = () => {
    return (
      <>
        <SkeletonTheme color="grey" highlightColor="#bfbfbf">
          {download.map((item, index) => (
            <a
              href={`${item.jenis_file == "drive" ? "" : dataUrl.download()}${
                item.file
              }`}
              target="_blank"
              download
              key={`item-${index}`}>
              <div className="item">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <Skeleton height={50} width={100} />
                    <div className="title-item">
                      {" "}
                      <Skeleton height={10} width={40} />
                    </div>
                  </div>
                  <div className="icon">
                    <Skeleton height={40} width={40} />
                  </div>
                </div>
              </div>
            </a>
          ))}
        </SkeletonTheme>
      </>
    );
  };

  return (
    <>
      <div className=" post-page download">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="title text-center">Download</div>
              <div className="list-item">
                <div className="row justify-content-center">
                  <div className="col-8">
                    {onGetData ? showSkeleton() : showData()}
                    {/* <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={imgPdf} className="img-file"></img>
                          <div className="title-item">
                            Form Registration Crew Sembilan
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={iconDownload}
                            className="icon-download"></img>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={listPage.last_page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
