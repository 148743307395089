import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiUrl, dataUrl } from "../actions/index";

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const [listData, setData] = useState([]);
  const fetchData = () => {
    fetch(`${apiUrl.detialPortofolio(params.id)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, []);
  }, []);

  return <WrappedComponent data={listData} />;
};

export default withRouter;
