import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../../assets/images/logo.png";
import imgbottom from "../../assets/images/post/img-bottom.svg";
import imgtop from "../../assets/images/post/img-top.svg";
import "../../assets/scss/home/home.css";
import bgDefault from "../../assets/images/post/bg-default-blog.png";
import { apiUrl, dataUrl } from "../../actions/index";
import Nav from "../../parts/Nav";
import ReactPaginate from "react-paginate";
import moment from "moment";
import 'moment/locale/id.js'

export default function MoreBlog(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [blog, setBlog] = useState([]);
  const [arsip, setArsip] = useState([]);
  const [kategori, setKategori] = useState([]);
  const [listPage, setPage] = useState([]);
  const [delaySearch, setDelay] = useState('');
  const [currentYear, setYear] = useState('');
  const [filter, setFilter] = useState({
    currentPage: 1,
    keyword: "",
    month: "",
    year: "",
    category: "",
  });
  // const fetchData = () => {
  //   fetch(
  //     `${apiUrl.blog()}&filter_bulan&filter_tahun&filter_keyword=&filter_kategori&page=1`
  //   )
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setBlog(data.list.data);
  //       setKategori(data.kategori);
  //       setArsip(data.arsip);
  //       setPage(data.list);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  const filterData = (props) => {
    fetch(
      `${apiUrl.blog()}&filter_bulan=${props.month}&filter_tahun=${
        props.year
      }&filter_keyword=${props.keyword}&filter_kategori=${
        props.category
      }&page=${props.currentPage}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBlog(data.list.data);
        setKategori(data.kategori);
        setArsip(data.arsip);
        setPage(data.list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    // setTimeout(() => {
      if (searchParams.get('filter_kategori')){
        filter.category = searchParams.get('filter_kategori');
      }
      if (searchParams.get('page')){
        filter.currentPage = searchParams.get('page');
      }
      if (searchParams.get('year')){
        filter.year = searchParams.get('year');
        setYear(searchParams.get('year'))
      }
      if (searchParams.get('month')){
        filter.month = searchParams.get('month');
      }
      if (searchParams.get('keyword')){
        filter.keyword = searchParams.get('keyword');
      }
      setFilter(filter)
      filterData(filter);
    // });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    filter.currentPage = currentPage;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('page', currentPage);
    window.history.replaceState(null, null, 'blog?'+urlParams.toString());

    setFilter(filter);
    filterData(filter);
  };

  const handleKeyword = (event) => {
    if (delaySearch) {
      clearTimeout(delaySearch)
    }
    var delay = setTimeout(() => {
      clearTimeout(delaySearch)
      const setKeyword = event.target.value;
      filter.currentPage = 1;
      filter.category = ''
      filter.month = ''
      filter.year = '';
      filter.keyword = setKeyword;
      window.history.replaceState(null, null, setKeyword ? `blog?keyword=${setKeyword}` : 'blog');
      setFilter(filter);
      filterData(filter);
    }, 1500);

    setDelay(delay);
  };
  const handleCategory = (event) => {
    const kategoriValue = event.target.dataset.value;
    filter.category = kategoriValue;
    filter.currentPage = 1;
    filter.keyword = ''
    filter.month = ''
    filter.year = ''
    filter.month = ''

    window.history.replaceState(null, null,`blog?filter_kategori=${kategoriValue}`);

    setFilter(filter);
    filterData(filter);
  };
  const handleYear = (event) => {
    const yaerValue = event.target.dataset.value;
    const monthValue = event.target.dataset.month;
    filter.year = yaerValue;
    filter.category = ''
    filter.keyword = ''
    filter.currentPage = 1
    filter.month = monthValue

    window.history.replaceState(null, null,`blog?year=${yaerValue}&month=${monthValue}`);

    setFilter(filter);
    filterData(filter);
  };
  // const listGambar = this.props.data.portofolio_gambar;
  // const showData = () => {
  //   return (
  //     <>
  //       <div className="date">
  //         {`${this.props.data.portofolio_detail.agency} - ${this.props.data.portofolio_detail.brand}  / `}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal)} -{" "}
  //         {this.formatDate(this.props.data.portofolio_detail.tanggal_selesai)}
  //       </div>
  //       <div className="title">{this.props.data.portofolio_detail.judul}</div>

  //       <div
  //         className="desc"
  //         dangerouslySetInnerHTML={{
  //           __html: this.props.data.portofolio_detail.keterangan,
  //         }}></div>
  //     </>
  //   );
  // };
  // const showSkeleton = () => {
  //   return (
  //     <>
  //       <SkeletonTheme color="grey" highlightColor="#bfbfbf">
  //         <div className="title">
  //           {" "}
  //           <Skeleton height={20} width={300} />
  //         </div>

  //         <div className="desc">
  //           {" "}
  //           <Skeleton height={160} />
  //         </div>
  //       </SkeletonTheme>
  //     </>
  //   );
  // };

  const strip = (string) => {
    const regex = /(<([^>]+)>)/gi;
    const newString = string.replace(regex, "");
    return newString.trimStart()
  }

  return (
    <>
      <div className="post-page post">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="row">
                <div className="col-md-10">
                  <div className="title-section">Blog</div>
                </div>
                <div className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Search"
                    onChange={handleKeyword}></input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <div className="list-item">
                    <div className="row">
                      {blog.map((item, index) => (
                        <div
                          className="col-md-4 col-12 mb-4"
                          key={`item-${index}`}>
                          <Link className="item" to={`/blog/${item.id}`}>
                            <img
                              src={
                                `${item.thumbnail == null}`
                                  ? bgDefault
                                  : `${dataUrl.blog()}${item.thumbnail}`
                              }></img>
                            <div className="kategori-item">{item.kategori}</div>
                            <div className="title-item">{item.judul}</div>
                            <div
                              className="desc"
                             >{strip(item.isi)}</div>
                          </Link>
                        </div>
                      ))}

                      {/* <div className="col-md-4 col-12 mb-4">
                        <Link
                          className="item">
                          <img src={bgDefault}></img>
                          <div className="kategori-item">Opinion</div>
                          <div className="title-item">AROGANSI AGENCY 1 - Sebuah Catatan Perjalanan</div>
                          <div className="desc">Lorem ipsum dolor sit amet consectetur. Ultrices venenatis sit arcu varius enim adipiscing ut nec pharetra. Turpis nisl et dolor tellus fames varius integer vel.</div>
                        </Link>
                      </div>
                      <div className="col-md-4 col-12 mb-4">
                        <Link
                          className="item">
                          <img src={bgDefault}></img>
                          <div className="kategori-item">Opinion</div>
                          <div className="title-item">AROGANSI AGENCY 1 - Sebuah Catatan Perjalanan</div>
                          <div className="desc">Lorem ipsum dolor sit amet consectetur. Ultrices venenatis sit arcu varius enim adipiscing ut nec pharetra. Turpis nisl et dolor tellus fames varius integer vel.</div>
                        </Link>
                      </div>
                      <div className="col-md-4 col-12 mb-4">
                        <Link
                          className="item">
                          <img src={bgDefault}></img>
                          <div className="kategori-item">Opinion</div>
                          <div className="title-item">AROGANSI AGENCY 1 - Sebuah Catatan Perjalanan</div>
                          <div className="desc">Lorem ipsum dolor sit amet consectetur. Ultrices venenatis sit arcu varius enim adipiscing ut nec pharetra. Turpis nisl et dolor tellus fames varius integer vel.</div>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={listPage.last_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="col-md-2">
                  <div className="list-filter">
                    <div className="title-filter">Kategori</div>

                    {kategori.map((item, index) => (
                      <div className="">
                        <button
                          type="button"
                          className="item-filter"
                          onClick={handleCategory}
                          data-value={item}>
                          {item}
                        </button>
                      </div>
                    ))}

                    {/* <div className="item-filter">SEMUA</div>
                    <div className="item-filter">EVENT</div>
                    <div className="item-filter">NEXT EVENT</div>
                    <div className="item-filter">DIRECTORS DESK</div>
                    <div className="item-filter">OPINION</div>
                    <div className="item-filter">PROVOACTION</div> */}
                  </div>
                  <div className="list-filter">
                    <div className="title-filter">Arsip</div>
                    {arsip.map((item, index) => (
                      <div className="">
                        <button type="button" onClick={(e) => setYear(item.year != currentYear ? item.year : '')} className="item-filter">
                          {item.year}
                        </button>
                        <div className={`month ${item.year == currentYear ? 'd-block' : 'd-none'}`}>
                          {item.list.map((month, index) => (
                            <div
                              className="item-filter"
                              data-value={item.year}
                              data-month={month.bulan}
                              onClick={handleYear}>
                              {moment(`${item.year}-${month.bulan}-01`).format("MMMM")}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* <div className="item-filter">2023</div>
                    <div className="item-filter">2022</div>
                    <div className="item-filter">2021</div>
                    <div className="item-filter">2020</div>
                    <div className="item-filter">2019</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
