import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/news/news.css";
import bgDefault from "../assets/images/news/bg-default-news.png";
import imgNews1 from "../assets/images/news/img-news-1.png";
import imgNews2 from "../assets/images/news/img-news-2.png";
import particle1 from "../assets/images/family/particle-1.svg";
import particle2 from "../assets/images/family/particle-2.svg";
import { apiUrl, dataUrl } from "../actions/index";

export default function News({data}) {
  const [news, setNews] = useState([]);
  //   const url = process.env.REACT_APP_DATA_URL+"/download";
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setNews(data.news);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    setNews(data);
  }, [data]);
  return (
    <section className="news" id="section-news">
      <img src={particle1} className="particle"></img>
      <img src={particle2} className="particle"></img>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <div className="title text-center">News</div>
            <div className="list-item">
              <div className="row">
                {news.slice(0, 6).map((item, index) => (
                  <div className="col-md-4 col-12 mb-4" key={`item-${index}`}>
                    <Link
                      className="item"
                      to={`/news/${item.id}`}
                      >
                      <div className="wrapper-item" >
                        <div className="overlay"></div>
                        <img
                          src={
                            `${dataUrl.news()}${item.thumbnail}`
                              ? `${dataUrl.news()}${item.thumbnail}`
                              : { bgDefault }
                          }></img>
                        <div className="title-item">{item.judul}</div>
                      </div>
                    </Link>
                  </div>
                ))}
                {/* <div className="col-md-4 col-12 mb-4">
                  <Link className="item">
                    <div className="wrapper-item">
                      <div className="overlay"></div>
                      <img src={imgNews1}></img>
                      <div className="title-item">
                        Lowongan Senior Staff, Junior Manager Finance dan Admin
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-12 mb-4">
                  <Link className="item">
                    <div className="wrapper-item">
                      <div className="overlay"></div>
                      <img src={imgNews2}></img>
                      <div className="title-item">
                        Lowongan Perwira Pertama Audiovisual.id
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-12 mb-4">
                  <Link className="item">
                    <div className="wrapper-item">
                      <div className="overlay"></div>
                      <img src={bgDefault}></img>
                      <div className="title-item">Lowongan Staff Finance</div>
                    </div>
                  </Link>
                </div> */}
                {/* {news.slice(0, 6).map((item, index) => (
                  <div className="col-md-4 col-12 mb-4">
                    <Link
                      className="item"
                      to={`/blog/${item.id}`}
                      key={`item-${index}`}>
                      <div className="wrapper-item">
                        <div className="overlay"></div>
                        <img src={bgDefault}></img>
                        <div className="title-item">{item.judul}</div>
                      </div> */}

                {/* <div className="desc" dangerouslySetInnerHTML={{ __html: item.isi }}></div> */}
                {/* </Link>
                  </div>
                ))} */}
              </div>
            </div>
            <Link to="/news">
              <div className="text-center mt-3">
                <div className="btn btn-more-blue">See More</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
