import React, { Component } from "react";
import Hero from "../parts/Hero";
import Nav from "../parts/Nav";
import Service from "../parts/Service";
import Galery from "../parts/Galery";
import Experience from "../parts/Experience";
import Contact from "../parts/Contact";
import Maps from "../parts/Maps";
import Family from "../parts/Family";
import Timeline from "../parts/Timeline";
import Post from "../parts/Blog";
import News from "../parts/News";
import Download from "../parts/Download";
import Footer from "../parts/Footer";
import Explore from "../parts/Explore";
import "../assets/scss/home/home.css";
import $ from "jquery";
import { apiUrl, dataUrl } from "../actions/index";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      content_slide: [],
      alamat: [],
      blog: [],
      contact: [],
      download: [],
      family: [],
      news: [],
      portofolio: [],
      service: [],
      sk: "",
      year: {
        year_summary: "",
        year_summary_mobile: "",
      },
    };
  }

  fetchData = () => {
    fetch(`${apiUrl.home()}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          content_slide: data.content_slide,
          alamat: data.alamat,
          blog: data.blog,
          contact: data.contact,
          download: data.download,
          family: data.family,
          news: data.news,
          portofolio: data.portofolio,
          service: data.service,
          year: {
            year_summary: data.year.year_summary,
            year_summary_mobile: data.year.year_summary_mobile,
          },
          sk : data.sk
        });
        // setNews(data.news);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  componentDidMount() {
    this.fetchData();
    $(window).scroll(function () {
      var scrollPos = $(this).scrollTop();
      let navbar = $(".nav");
      let windowPosition = window.scrollY > 0;

      navbar.toggleClass("scrolling-active", windowPosition);

      var windowHeight = window.innerHeight;
      var timelineOuter = $(".timeline").outerHeight();
      var footerOuter = $(".footer").outerHeight();

      var timelineHeight = scrollPos / (timelineOuter - windowHeight);
      var footerHeight = scrollPos / (footerOuter - windowHeight);
      $(".gunung-1").css({
        transform: "translateY(" + scrollPos / 1.5 + "px)",
      });

      $(".gunung-2").css({
        transform: "translateY(" + scrollPos / 1.5 + "px)",
      });

      $(".gunung-3").css({
        transform: "translateY(" + scrollPos / 1.5 + "px)",
      });

      $(".gunung-4").css({
        transform: "translateY(" + scrollPos / 1.5 + "px)",
      });

      // $(".img-center").css({
      //   transform: "translateY(" + scrollPos * -0.1 + "px)",
      // });

      $(".kota").css({
        transform: "translateY(" + scrollPos * -0.1 + "px)",
      });

      $(".particle-behind").css({
        transform: "translateY(" + scrollPos * -0.1 + "px)",
      });

      $(".particle-bottom").css({
        transform: "translateY(" + scrollPos * -0.3 + "px)",
      });
      $(".particle-timeline").css({
        transform: "translateY(" + timelineHeight * -20 + "px)",
      });
      if (window.matchMedia("(max-width: 992px)").matches) {
        $(".particle-footer").css({
          transform: "translateY(" + footerHeight * 20 + "px)",
        });
        $(".particle-timeline").css({
          transform: "translateY(" + timelineHeight * -6 + "px)",
        });
      }
      if (
        window.matchMedia("(min-width: 992px)" && "(max-width: 1366px)").matches
      ) {
        $(".particle-timeline").css({
          transform: "translateY(" + timelineHeight * -10 + "px)",
        });
        $(".particle-footer").css({
          transform: "translateY(" + footerHeight * -5 + "px)",
        });
      } else {
        $(".particle-footer").css({
          transform: "translateY(" + footerHeight * -20 + "px)",
        });
      }
    });

    var onNav = false;
    $(".nav .nav-item.nav-section").click(function () {
      onNav = true;
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top + 30,
        },
        500
      );
      setTimeout(() => {
        onNav = false;
      }, 550);
    });
  }

  render() {
    return (
      <>
        <Nav></Nav>
        <Hero></Hero>
        <Explore data={this.state.content_slide}></Explore>
        <Service data={this.state.service}></Service>
        <Timeline data={this.state.year}></Timeline>
        <Experience data={this.state.portofolio}></Experience>
        <Family data={this.state.family}></Family>
        <Contact data={this.state.contact} dataSk={this.state.sk} rowAddress={this.state.alamat}></Contact>
        <Maps data={this.state.alamat}></Maps>
        <Post data={this.state.blog}></Post>
        <News data={this.state.news}></News>
        <Download data={this.state.download}></Download>
        <Footer></Footer>
      </>
    );
  }
}
