import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/post/post.css";
import bgDefault from "../assets/images/post/bg-default-blog.png";
import { apiUrl, dataUrl } from "../actions/index";

export default function Post({data}) {
  const [blog, setBlog] = useState([]);
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setBlog(data.blog);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    setBlog(data);
  }, [data]);
  return (
    <section className="post" id="section-post">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <div className="title text-center">BLOG</div>
            <div className="list-item">
              <div className="row">
                {blog.slice(0, 6).map((item, index) => (
                  <div className="col-md-4 col-12 mb-4" key={`item-${index}`}>
                    <Link
                      className="item"
                      to={`/blog/${item.id}`}
                      >
                      <img src={`${dataUrl.blog()}/${item.thumbnail}` ? `${dataUrl.blog()}${item.thumbnail}` : {bgDefault}}></img>
                      <div className="kategori-item">{item.kategori}</div>
                      <div className="title-item">{item.judul}</div>
                    </Link>
                  </div>
                ))}
                {/* <div className="col-md-4 col-12 mb-4">
                    <Link
                      className="item">
                      <img src={bgDefault}></img>
                      <div className="kategori-item">Opinion</div>
                      <div className="title-item">AROGANSI AGENCY 1 - Sebuah Catatan Perjalanan</div>
                    </Link>
                  </div> */}
              </div>

              {/* <Link to="/blog" className="item">
                You City Tour Yogyakarta
                <div className="desc">
                  Lorem ipsum dolor sit amet consectetur. Ultrices venenatis sit
                  arcu varius enim.
                </div>
              </Link> */}
            </div>
            <Link to="/blog">
              <div className="text-center mt-5">
                <div className="btn btn-more">See More</div>
              </div>
            </Link>
          </div>
          {/* <div className="col-lg-3 col-md-12 offset-lg-1">
            <div className="title">RECENT POST</div>
            <div className="list-item">
              {post.map((item, index) => (
                <Link
                  className="item"
                  to={`/post/${item.id}`}
                  key={`item-${index}`}
                >
                  {item.judul}
                </Link>
              ))}
            </div>
          </div> */}
          {/* <div className="col-lg-3 col-md-12 offset-lg-1">
            <div className="title">DOWNLOAD</div>
            <div className="list-item">
              {download.map((item, index) => (
                <a
                  className="item"
                  download
                  target="blank"
                  href={`${url}/${item.file}`}
                  key={`item-${index}`}
                >
                  {item.judul}
                </a>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
