var _url = process.env.REACT_APP_API_URL
var idWeb = process.env.REACT_APP_ID_WEB
var _data = process.env.REACT_APP_DATA_URL
// if (window.location.origin == "https://ciptarasajawara.com" || window.location.origin == "https://www.ciptarasajawara.com") {
//     _url = "https://storage.ciptarasajawara.com";
// }
const apiUrl = {
    home: () => {
        return `${_url}/home?id_web=${idWeb}`
    },
    portofolio: () => {
        return `${_url}/portofolio?id_web=${idWeb}`
    },
    detialPortofolio: (id) => {
        return `${_url}/detail_portofolio/${id}?id_web=${idWeb}`
    },
    download: () => {
        return `${_url}/download?id_web=${idWeb}`
    },
    sosmed: () => {
        return `${_url}/social-media?id_web=${idWeb}`
    },
    news: () => {
        return `${_url}/news?id_web=${idWeb}`
    },
    
    detailNews: (id) => {
        return `${_url}/detail_news/${id}?id_web=${idWeb}`
    },

    blog: () => {
        return `${_url}/blog?id_web=${idWeb}`
    },
    detailBlog: (id) => {
        return `${_url}/detail_blog/${id}?id_web=${idWeb}`
    },

    detailService: (id) => {
        return `${_url}/detail_service/${id}?id_web=${idWeb}`
    },
    sosmed: () => {
        return `${_url}/social-media?id_web=${idWeb}`
    },

    freelance : () => {
        return "https://api.finanta.id/"
    },

    bank : () => {
        return "https://api.finanta.id/master/bank"
    },
    
    provinsi : () => {
        return "https://api.finanta.id/master/list_provinsi"
    },
    
    kabupaten : (id_provinsi) => {
        return `https://api.finanta.id/master/list_kabupaten?id_prov=${id_provinsi}`
    },
    
    kecamatan : (id_kabupaten) => {
        return `https://api.finanta.id/master/list_kecamatan?id_kab=${id_kabupaten}`
    }
    

}

const dataUrl = {
    experience: () => {
        return `${_data}/portofolio/`
    },
    blog: () => {
        return `${_data}/blog/`
    },
    download: () => {
        return `${_data}/download/`
    },
    year: () => {
        return `${_data}/year-summary/`
    },
    service: () => {
        return `${_data}/service/`
    },
    news: () => {
        return `${_data}/news/`
    },

}

export {
    dataUrl, apiUrl
};

