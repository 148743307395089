import React, { useEffect, useState } from "react";
import "../assets/scss/service/service.css";
import particle from "../assets/images/service/particle.svg";
import background from "../assets/images/service/background.png";
import item1 from "../assets/images/service/bg-item-1.png";
import item2 from "../assets/images/service/bg-item-2.png";
import item3 from "../assets/images/service/bg-item-3.png";
import item4 from "../assets/images/service/bg-item-4.png";
import item5 from "../assets/images/service/bg-item-5.png";
import item6 from "../assets/images/service/bg-item-6.png";
import { NavLink } from "react-router-dom";
import { apiUrl, dataUrl } from "../actions/index";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function Service({ data }) {
  const [service, setService] = useState([]);
  const [onGetData, setLoading] = useState(true);

  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setService(data.service);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    setService(data);
    setLoading(false);
  }, [data]);

  const showData = () => {
    return (
      <>
        {service.map((item, index) => (
          <div className="col-4 mb-5" key={`item-${index}`}>
            <NavLink href="" to={`/service/${item.id}`} className=" item">
              <img src={`${dataUrl.service()}${item.thumbnail}`}></img>
            </NavLink>
          </div>
        ))}
      </>
    );
  };
  const showSkeleton = () => {
    return (
      <>
        {service.map((item, index) => (
          <div className="col-4 mb-5" key={`item-${index}`}>
            <NavLink href="" to={`/service/${item.id}`} className=" item">
              <Skeleton height={217} width={`100%`} />
            </NavLink>
          </div>
        ))}
      </>
    );
  };
  return (
    <section className="service" id="section-service">
      <img src={particle} className="particle"></img>
      <img src={background} className="bg-img"></img>
      <div className="container content">
        <div className="wrapper-service">
          <div className="title">THE SERVICES</div>
          <div className="row justify-content-center align-items-center mt-5">
            {onGetData ? showSkeleton() : showData()}
            {/* <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item1}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item2}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item3}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item4}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item5}></img>
              </NavLink>
            </div>
            <div className="col-4 mb-5">
              <NavLink href="" className=" item">
                <img src={item6}></img>
              </NavLink>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
