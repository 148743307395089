import React from "react";
import Explore from "../parts/Explore";
import "../assets/scss/hero/hero.css";
import bg from "../assets/images/header/bg-atas.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import kota1 from "../assets/images/header/kota-1.svg";
import imgcenter from "../assets/images/header/img-center.png";
import imgcentermobile from "../assets/images/header/img-center-mobile.svg";
import gunung1 from "../assets/images/header/gunung-1.svg";
import gunung2 from "../assets/images/header/gunung-2.svg";
import gunung3 from "../assets/images/header/gunung-3.svg";
import gunung4 from "../assets/images/header/gunung-4.svg";
import particletop from "../assets/images/header/particle-top.svg";
import particlebottom from "../assets/images/header/particle-bottom.svg";
import particlecenter from "../assets/images/header/particle-center.svg";
import particlebehind from "../assets/images/header/particle-behind.svg";

import gif1 from "../assets/images/header/gif/gif-1.gif";
import gif2 from "../assets/images/header/gif/gif-2.gif";
import gif3 from "../assets/images/header/gif/gif-3.gif";
import gif4 from "../assets/images/header/gif/gif-4.gif";
import gif5 from "../assets/images/header/gif/gif-5.gif";
import gif6 from "../assets/images/header/gif/gif-6.gif";

import ikan1 from "../assets/images/header/ikan/ikan-01.svg";

import bgKota from "../assets/images/header/new/bg-kota.svg";
import kota from "../assets/images/header/new/kota.svg";
import rumputkanan from "../assets/images/header/new/rumput-kanan.svg";
import rumputkiri from "../assets/images/header/new/rumput-kiri.svg";
import rumputtengah from "../assets/images/header/new/rumput-tengah.svg";

export default function Hero() {
  return (
    <>
      <div className="wrapper-hero">
        <div className="hero">
          <img src={bg} className="bg"></img>
          <img src={imgcenter} className="img-center"></img>

          {/* <img src={bgKota} className="gunung-kota"></img> */}
          {/* <img src={kota} className="kota-1"></img>
          <img src={rumputkanan} className="rumput rumputkanan"></img>
          <img src={rumputkiri} className="rumput rumputkiri"></img>
          <img src={rumputtengah} className="rumput rumputtengah"></img> */}

          <img src={imgcentermobile} className="img-center-mobile"></img>
          {/* <img src={kota1} className="kota"></img> */}
          <div className="wrapper-gunung">
            <img src={gunung1} className="gunung-1 gunung"></img>
            <img src={gunung2} className="gunung-2 gunung"></img>
            <img src={gunung3} className="gunung-3 gunung"></img>
            <img src={gunung4} className="gunung-4 gunung"></img>
          </div>

          <img src={particletop} className="particle-top"></img>
          {/* <img src={particlecenter} className="particle-center"></img> */}
          <img src={particlebehind} className="particle-behind"></img>
          {/* <img src={particlebehind} className="particle-behind-2"></img> */}
          {/* <img src={particlebottom} className="particle-bottom"></img> */}
          {/* <img src={ikan1} className=" ikan-1"></img> */}
          <img src={gif1} className="gif-1 gif"></img>
          <img src={gif2} className="gif-2 gif"></img>
          <img src={gif3} className="gif-3 gif"></img>
          <img src={gif4} className="gif-4 gif"></img>
          {/* <img src={gif5} className="gif-5 gif"></img>
          <img src={gif6} className="gif-6 gif"></img> */}
        </div>
      </div>
    </>
  );
}
