import React from "react";
import "../assets/scss/footer/footer.css";
import footer from "../assets/images/footer/footer.svg";
import footermobile from "../assets/images/footer/footer-mobile.svg";
import bg from "../assets/images/footer/bg.png";
import gif1 from "../assets/images/footer/gif/gif-1.gif";
import gif2 from "../assets/images/footer/gif/gif-2.gif";
import gif3 from "../assets/images/footer/gif/gif-3.gif";
import gif4 from "../assets/images/footer/gif/gif-4.gif";
import gif5 from "../assets/images/footer/gif/gif-5.gif";
import gif6 from "../assets/images/footer/gif/gif-6.gif";

import particle1 from "../assets/images/footer/particle-1.svg";
import particle2 from "../assets/images/footer/particle-2.svg";
import particle3 from "../assets/images/footer/particle-3.svg";
import particle4 from "../assets/images/footer/particle-4.svg";
import particle5 from "../assets/images/footer/particle-5.svg";
import particle6 from "../assets/images/footer/particle-6.svg";
import particle7 from "../assets/images/footer/particle-7.svg";
import particle8 from "../assets/images/footer/particle-8.svg";

export default function Footer() {
  return (
    <section className="footer">
      <img src={footer} className="footer-img"></img>
      <img src={footermobile} className="footer-mobile"></img>
      <img src={bg} className="bg"></img>
      <img src={gif1} className="gif gif-1"></img>
      <img src={gif2} className="gif gif-2"></img>
      <img src={gif3} className="gif gif-3"></img>
      <img src={gif4} className="gif gif-4"></img>
      <img src={gif5} className="gif gif-5"></img>
      <img src={gif6} className="gif gif-6"></img>
      <div className="wrapper-particle">
        <img src={particle1} className="particle-1 particle-footer"></img>
        <img src={particle2} className="particle-2 particle-footer"></img>
        <img src={particle3} className="particle-3 particle-footer"></img>
        <img src={particle4} className="particle-4 particle-footer"></img>
        <img src={particle5} className="particle-5 particle-footer"></img>
        <img src={particle6} className="particle-6 particle-footer"></img>
        <img src={particle7} className="particle-7 particle-footer"></img>
        <img src={particle8} className="particle-8 particle-footer"></img>
      </div>
      <div className="copyright">
        <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
        <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
      </div>
    </section>
  );
}
