import React, { Component, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-multi-carousel/lib/styles.css";
import logo from "../assets/images/logo.png";
import imgbottom from "../assets/images/post/img-bottom.svg";
import imgtop from "../assets/images/post/img-top.svg";
import "../assets/scss/home/home.css";
import "../assets/scss/portofolio/portofolio.css";
import Nav from "../parts/Nav";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { apiUrl, dataUrl } from "../actions/index";
import img1 from "../assets/images/portofolio/img-portofolio.png";

export default function MorePortofolio() {
  function formatDate(newdate) {
    const date = new Date(newdate);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }

  const [items, setItems] = useState([]);
  const [onGetData, setLoading] = useState(true);
  const [brand, setBrand] = useState({
    value: "",
    label: "",
  });
  const [filter, setFilter] = useState({
    year: "",
    month: "",
    month_full: null,
    year_full: null,
    brand_full: null,
    currentPage: 1,
    brand: "",
    keyword: "",
  });

  const getData = async (currentPage) => {
    const res = await fetch(
      `${apiUrl.portofolio()}/portofolio?page=&filter_tahun=&filter_bulan=&filter_keyword=&filter_brand=`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        setItems(data);
        const brand_data = data.brand.map(({ id, nama }) => ({
          label: nama,
          value: id,
        }));
        setBrand(brand_data);
        const total = data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const filterData = async (props) => {
    const res = await fetch(
      `${apiUrl.portofolio()}/portofolio?page=${
        props.currentPage
      }&filter_bulan=${props.month}&filter_tahun=${props.year}&filter_keyword=${
        props.keyword
      }&filter_brand=${props.brand}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    filter.currentPage = currentPage;
    setFilter(filter);
    filterData(filter);
  };

  const handleMonth = (event) => {
    const setMonth = event.value;
    filter.month = setMonth;
    filter.month_full = event;
    setFilter(filter);
    filterData(filter);
  };
  const handleYear = (event) => {
    const setYear = event.value;
    filter.year = setYear;
    filter.year_full = event;
    setFilter(filter);
    filterData(filter);
  };

  const handleKeyword = (event) => {
    const setKeyword = event.target.value;
    filter.keyword = setKeyword;
    setFilter(filter);
    filterData(filter);
  };
  const handleBrand = (event) => {
    const item = [];
    for (const key in event) {
      if (Object.hasOwnProperty.call(event, key)) {
        const element = event[key];
        item.push(element.value);
      }
    }
    filter.brand_full = event;
    filter.brand = item.join(",");
    setFilter(filter);
    filterData(filter);
  };

  const resetFilter = () => {
   const filter = {
      year: "",
      month: "",
      currentPage: 1,
      brand: "",
      keyword: "",
      brand_full: null,
      year_full: null,
      month_full: null
    }
    handleMonth("")
    setFilter(filter);
    filterData(filter)
  };

  useEffect(() => {
    // setTimeout(() => {
    getData();
    // });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  const listMonth = [
    { value: "01", label: "Janauari" },
    { value: "02", label: "Frebruari" },
    { value: "03", label: "Maret" },
    { value: "04", label: "April" },
    { value: "05", label: "Mei" },
    { value: "06", label: "Juni" },
    { value: "07", label: "Juli" },
    { value: "08", label: "Agustus" },
    { value: "09", label: "September" },
    { value: "10", label: "Oktober" },
    { value: "11", label: "November" },
    { value: "12", label: "Desember" },
  ];
  const listYear = [
    { value: "2009", label: "2009" },
    { value: "2010", label: "2010" },
    { value: "2012", label: "2012" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#0e2c4e;" : null,
        color: isFocused ? "#ffff;" : "#333333",
      };
    },
  };
  const showData = () => {
    return (
      <>
        <div className="more-portofolio">
          <div className="row align-items-center">
            <div className="col-2">
              <Select
                options={listMonth}
                placeholder="Bulan"
                styles={colourStyles}
                value={filter.month_full}
                onChange={handleMonth}
              />
            </div>
            <div className="col-2">
              <Select
                options={listYear}
                placeholder="Tahun"
                styles={colourStyles}
                value={filter.year_full}
                onChange={handleYear}
              />
            </div>
            <div className="col-5">
              <Select
                options={brand}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Brand"
                value={filter.brand_full}
                onChange={handleBrand}
                styles={colourStyles}
              />
            </div>
            <div className="col-2">
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="kata kunci"
                value={filter.keyword}
                onChange={handleKeyword}></input>
            </div>
            <div className="col-1">
              <div className="">
                <button
                  className="btn btn-primary"
                  onClick={() => resetFilter()}>
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {items.listData.data.map((item, index) => (
              <div className="col-4" key={`item-${index}`}>
                <div className="item-activity item-activity-1">
                  <Link to={`/portofolio/${item.id}`}>
                    <img
                      src={`${dataUrl.experience()}${item.thumbnail}`}
                      alt={`${dataUrl.experience()}${item.thumbnail}`}></img>
                    <div className="title-item">{item.judul}</div>
                    <div className="d-flex align-items-center">
                      <div className="line"></div>
                      <div className="desc-item">
                        {item.nama_brand} / {formatDate(item.tanggal_mulai)} -{" "}
                        {item.tanggal_selesai
                          ? formatDate(item.tanggal_selesai)
                          : ""}
                      </div>
                    </div>
                    <div></div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={items.listData.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </>
    );
  };
  const showSkeleton = () => {
    return (
      <>
        <SkeletonTheme color="grey" highlightColor="#bfbfbf">
          <div className="title">
            {" "}
            <Skeleton height={20} width={300} />
          </div>

          <div className="desc">
            {" "}
            <Skeleton height={160} />
          </div>
        </SkeletonTheme>
      </>
    );
  };

  return (
    <>
      <div className="post-page">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              {" "}
              {onGetData ? showSkeleton() : showData()}
            </div>
          </div>
          <div className="container">
            <div className="content">
              <div className="desc desc-long"></div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}