import React, { useEffect, useState } from "react";
import "../assets/scss/experience/experience.css";
import { Link } from "react-router-dom";
import img1 from "../assets/images/portofolio/img-portofolio.png";
import img2 from "../assets/images/portofolio/img-portofolio-2.png";
import img3 from "../assets/images/portofolio/img-portofolio-3.png";
import img4 from "../assets/images/portofolio/img-portofolio-4.png";
import { apiUrl, dataUrl } from "../actions/index";
import { v4 } from "uuid";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function Experience({data}) {
  function formatDate(newdate) {
    const date = new Date(newdate);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }
  const [experience, setExperience] = useState([]);
  const [onGetData, setLoading] = useState(true);
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setExperience(data.portofolio);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    setExperience(data);
    setLoading(false);
  }, [data]);

  const showData = () => {
    return (
      <>
        {experience.slice(0, 4).map((item, index) => (
          <>
            {index > 0 ? (
             
                <div
                  // key={v4()}
                  key={item}
                  className={`item-activity item-activity-${
                    index + 1
                  } item-right`} >
                  <Link to={`/portofolio/${item.id}`}   key={index}>
                    <div className="d-flex h-100">
                      <img
                        src={`${dataUrl.experience()}${item.thumbnail}`}
                        alt={`${dataUrl.experience()}${item.thumbnail}`}></img>
                      <div>
                        <div className="title-item ms-3">{item.judul}</div>
                        <div className=" d-flex align-items-center ms-3">
                          <div className="line"></div>
                          <div className="desc-item">
                            {item.nama_brand} / {formatDate(item.tanggal_mulai)}{" "}
                            -{" "}
                            {item.tanggal_selesai
                              ? formatDate(item.tanggal_selesai)
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
             
            ) : (
           
                <div className="item-activity item-activity-1" key={item}>
                  <Link to={`/portofolio/${item.id}`}>
                    <img
                      src={`${dataUrl.experience()}${item.thumbnail}`}
                      alt={`${dataUrl.experience()}${item.thumbnail}`}></img>
                    <div className="title-item">{item.judul}</div>
                    <div className="d-flex align-items-center">
                      <div className="line"></div>
                      <div className="desc-item">
                        {item.nama_brand} / {formatDate(item.tanggal_mulai)} -{" "}
                        {item.tanggal_selesai
                          ? formatDate(item.tanggal_selesai)
                          : ""}
                      </div>
                    </div>
                    <div></div>
                  </Link>
                </div>
             
            )}
          </>
        ))}
      </>
    );
  };
  const showSkeleton = () => {
    return (
      <>
        <SkeletonTheme color="grey" highlightColor="#bfbfbf">
          {experience.slice(0, 4).map((item, index) => (
            <>
              {index > 0 ? (
                <>
                  <div
                   key={index}
                    className={`item-activity item-activity-${
                      index + 1
                    } item-right`}>
                    <Link to={`/portofolio/${item.id}`}   key={index}>
                      <div className="d-flex">
                        <Skeleton height={153} width={223} />
                        <div>
                          <div className="title-item ms-3">
                            <Skeleton height={10} width={179} />
                          </div>
                          <div className=" d-flex align-items-center ms-3">
                            <div className="line"></div>
                            <div className="desc-item">
                              <Skeleton height={10} width={120} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="item-activity item-activity-1" key={item}>
                    <Link to={`/portofolio/${item.id}`}>
                      <Skeleton height={400} width={`100%`} />
                      <div className="title-item">
                        <Skeleton height={10} width={179} />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="line"></div>
                        <div className="desc-item">
                          <Skeleton height={10} width={120} />
                        </div>
                      </div>
                      <div></div>
                    </Link>
                  </div>
                </>
              )}
            </>
          ))}
        </SkeletonTheme>
      </>
    );
  };

  return (
    <section className="experience" id="section-experience">
      <div className="container">
        <div className="title">THE EXPERIENCE</div>
        <div className="list-activity">
          {onGetData ? showSkeleton() : showData()}
          {/* <div className="item-activity item-activity-1">
            <Link route="/aktivitas/detail">
              <img src={img1} alt={img1}></img>
              <div className="title-item">Bayer Annual Conference</div>
              <div className="d-flex align-items-center">
                <div className="line"></div>
                <div className="desc-item">Bayer Indonesia Natcon / 10 - 12 Februari 2020</div>
              </div>
              <div></div>
            </Link>
          </div>
          <div className="item-activity item-activity-2 item-right">
            <Link route="/aktivitas/detail">
              <div className="d-flex">
                <img src={img4} alt={img4}></img>
                <div>
                  <div className="title-item ms-3">
                  MY PERTAMINA FUNWALK 2019
                  </div>
                  <div className=" d-flex align-items-center ms-3">
                    <div className="line"></div>
                    <div className="desc-item">DPRD Kota Semarang / 6 JANUARI 2019</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="item-activity item-activity-3 item-right">
            <Link route="/aktivitas/detail">
              <div className="d-flex">
                <img src={img3} alt={img3}></img>
                <div>
                  <div className="title-item ms-3">
                  KAPAL API MATA NAJWA BOYOLALI
                  </div>
                  <div className="d-flex align-items-center ms-3">
                    <div className="line"></div>
                    <div className="desc-item">KAPAL API / 22 - 23 Februari 2019</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="item-activity item-activity-4 item-right">
            <Link route="/aktivitas/detail">
              <div className="d-flex">
                <img src={img2} alt={img4}></img>
                <div>
                  <div className="title-item ms-3">
                    LACTOGROW MINI BANG SOLO
                  </div>
                  <div className="d-flex align-items-center ms-3">
                    <div className="line"></div>
                    <div className="desc-item">KAPAL API / 4 April 2017</div>
                  </div>
                </div>
              </div>
            </Link>
          </div> */}
        </div>

        {/* <div className="d-flex justify-content-center flex-wrap mt-5">
          <div className="wrapper-item">
            {experience.map((item, index) => (
              <Link
                to={`/portofolio/${item.id}`}
                className="item"
                key={`item-${index}`}
              >
                {item.judul}
              </Link>
            ))}
          </div>
        </div> */}
        <Link to="/portofolio">
          <div className="text-center">
            <div className="btn btn-more">See More</div>
          </div>
        </Link>
      </div>
    </section>
  );
}
