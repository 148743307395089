import React, { useEffect, useState } from "react";
import "../assets/scss/maps/maps.css";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { apiUrl, dataUrl } from "../actions/index";

export default function Maps({ data }) {
  const [listData, setData] = useState([]);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  // const fetchData = () => {
  //   fetch(apiUrl.home())
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       const dataAlamat = data.alamat.find((e) => e.alamat_utama == 'ya');
  //       setCenter({
  //         lat: parseFloat(dataAlamat.lat),
  //         lng: parseFloat(dataAlamat.lang)
  //       })
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  useEffect(() => {
    const dataAlamat = data.find((e) => e.alamat_utama == "ya");
    setCenter({
      lat: dataAlamat ? parseFloat(dataAlamat.lat) : 0,
      lng: dataAlamat ? parseFloat(dataAlamat.lang) : 0,
    });
  }, [data]);
  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAy9K0C7-KQxteQAQ_LeDtqztq1ANzpQ5o",
  });

  const [map, setMap] = React.useState(null);

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} zoom={14} center={center}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}
