import React, { useEffect, useState } from "react";
import "../assets/scss/family/family.css";
import particle1 from "../assets/images/family/particle-1.svg";
import particle2 from "../assets/images/family/particle-2.svg";

import jawara from "../assets/images/family/pt-jawara.png";
import { apiUrl } from "../actions";
import { Link } from "react-router-dom";

export default function Family({ data }) {
  const [divisi, setDivisi] = useState([]);
  const [subsidiary, setSubsidiary] = useState([]);
  const [subsidiaryTop, setSubsidiaryTop] = useState([]);
  const [subsidiaryBottom, setSubsidiaryBottom] = useState([]);
  const [affiliated, setAffiliated] = useState([]);
  const dataUrl = process.env.REACT_APP_DATA_URL+"/family";
  // const fetchData = () => {
  //   fetch(apiUrl.home())
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       const result = data.family;
  //       const categoryDivisi = result.filter(
  //         (item) => item.kategori === "Divisi"
  //       );
  //       const categorySubsidiary = result.filter(
  //         (item) => item.kategori === "Anak Perusahaan"
  //       );
  //       const categorySubsidiaryTop = categorySubsidiary.slice(0, 4);
  //       const categorySubsidiaryBottom = categorySubsidiary.slice(4, 10);

  //       const _categoryAffiliated = result.filter(
  //         (item) => item.kategori === "Perusahaan Terafiliasi"
  //       );
  //       const categoryAffiliated = [];
  //       for (const key in _categoryAffiliated) {
  //         if (Object.hasOwnProperty.call(_categoryAffiliated, key)) {
  //           const element = _categoryAffiliated[key];
  //           var i = parseInt(key) + 1;
  //           categoryAffiliated.push(element);
  //           if (i % 4 === 0) {
  //             categoryAffiliated.push({
  //               id: "break",
  //             });
  //           }
  //         }
  //       }
  //       setSubsidiaryBottom(categorySubsidiaryBottom);
  //       setSubsidiaryTop(categorySubsidiaryTop);
  //       setDivisi(categoryDivisi);
  //       setSubsidiary(categorySubsidiary);
  //       setAffiliated(categoryAffiliated);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    const result = data;
    const categoryDivisi = result.filter((item) => item.kategori === "Divisi");
    const categorySubsidiary = result.filter(
      (item) => item.kategori === "Anak Perusahaan"
    );
    const categorySubsidiaryTop = categorySubsidiary.slice(0, 4);
    const categorySubsidiaryBottom = categorySubsidiary.slice(4, 10);

    const _categoryAffiliated = result.filter(
      (item) => item.kategori === "Perusahaan Terafiliasi"
    );
    const categoryAffiliated = [];
    for (const key in _categoryAffiliated) {
      if (Object.hasOwnProperty.call(_categoryAffiliated, key)) {
        const element = _categoryAffiliated[key];
        var i = parseInt(key) + 1;
        categoryAffiliated.push(element);
        if (i % 4 === 0) {
          categoryAffiliated.push({
            id: "break",
          });
        }
      }
    }
    setSubsidiaryBottom(categorySubsidiaryBottom);
    setSubsidiaryTop(categorySubsidiaryTop);
    setDivisi(categoryDivisi);
    setSubsidiary(categorySubsidiary);
    setAffiliated(categoryAffiliated);
  }, [data]);

  const checkBreak = (index) => {
    if (index % 4 == 0) {
      return '<div class="w-100"></div>';
    }
    return "";
  };
  return (
    <section className="family" id="section-family">
      <img src={particle1} className="particle"></img>
      <img src={particle2} className="particle"></img>
      <div className="title">THE FAMILY</div>
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            <img src={jawara} className="jawara"></img>
          </div>
        </div>
        <div className="row list-family  justify-content-center">
          {divisi.map((item, index) => (
            <div
              className="col-lg-3 col-md-4 col-sm-12 item"
              key={`item-${index}`}>
              <a href={`${item.url}`} target="_blank">
                <img
                  src={`${dataUrl}/${item.logo}`}
                  className="img-family-1"></img>
              </a>
            </div>
          ))}
          {/* <div className="col-lg-3 col-md-4 col-sm-12 item">
            <img src={family1} className="img-family-1"></img>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 item">
            <img src={family2} className="img-family-1"></img>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 item">
            <img src={family3} className="img-family-1"></img>
          </div> */}
        </div>
        <div className="row list-family justify-content-center ">
          {subsidiaryTop.map((item, index) => (
            <div
              className="col-lg-2 col-md-3 col-sm-6 col-6 item"
              key={`item-${index}`}>
              <a href={`${item.url}`} target="_blank">
                <img
                  src={`${dataUrl}/${item.logo}`}
                  className="img-family-2"></img>
              </a>
            </div>
          ))}

          {/* <div className="col-lg-2 col-md-3 col-sm-6 col-6 item">
            <img src={family4} className="img-family-2"></img>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 col-6 item">
            <img src={family5} className="img-family-2"></img>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 col-6 item">
            <img src={family6} className="img-family-2"></img>
          </div>
          <div className="col-lg-2 col-md-3  col-sm-6  col-6 item">
            <img src={family7} className="img-family-2"></img>
          </div> */}
        </div>
        <div className="row  list-family justify-content-center row-family-3">
          {subsidiaryBottom.map((item, index) => (
            <div
              className="col-lg-2 col-md-3 col-sm-6 col-6 item"
              key={`item-${index}`}>
              <a href={`${item.url}`} target="_blank">
                <img
                  src={`${dataUrl}/${item.logo}`}
                  className="img-family-3"></img>
              </a>
            </div>
          ))}

          {/* <div className="col-md-2 col-lg-2 col-sm-6 item">
            <img src={family8} className="img-family-3"></img>
          </div>
          <div className="col-md-2 col-lg-2 col-sm-6  item">
            <img src={family9} className="img-family-3"></img>
          </div>
          <div className="col-md-2  col-lg-2 col-sm-6  item">
            <img src={family10} className="img-family-3"></img>
          </div>
          <div className="col-md-2  col-lg-2 col-sm-6  item">
            <img src={family11} className="img-family-3"></img>
          </div>
          <div className="col-md-2  col-lg-2 col-sm-6  item">
            <img src={family12} className="img-family-3"></img>
          </div>
          <div className="col-md-2 col-lg-2 col-sm-6  item">
            <img src={family13} className="img-family-3"></img>
          </div> */}
        </div>
        <div className="row list-family justify-content-center ">
          {affiliated.map((item, index) =>
            item.id == "break" ? (
              <div className="col-12 item" key={`item-${index}`}>
                <div
                  className="img-family-3"
                  style={{ width: "unset", height: "unset" }}></div>
              </div>
            ) : (
              <div
                className="col-lg-2 col-md-3 col-sm-6 col-6 item"
                key={`item-${index}`}>
                <a href={`${item.url}`} target="_blank">
                  <img
                    src={`${dataUrl}/${item.logo}`}
                    className="img-family-4"></img>
                </a>
              </div>
            )
          )}
          {/* <div className="col-lg-2 col-md-3 col-6 item">
            <img src={family14} className="img-family-4"></img>
          </div>
          <div className="col-lg-2 col-md-3 col-6 item">
            <img src={family15} className="img-family-4"></img>
          </div>
          <div className="col-lg-2 col-md-3 col-6 item">
            <img src={family16} className="img-family-4"></img>
          </div>
          <div className="col-lg-2 col-md-3 col-6 item">
            <img src={family17} className="img-family-4"></img>
          </div> */}
        </div>

        {/* <div className="row list-family justify-content-center ">
          <div className="col-6 col-md-3 col-lg-2 item">
            <img src={family18} className="img-family-2"></img>
          </div>
          <div className="col-6 col-md-3  col-lg-2 item">
            <img src={family19} className="img-family-2"></img>
          </div>
        </div> */}
        {/* <div className="row justfy-content-center align-items-center">
          <div className="col-md-3 item"></div>
          <div className="col-md-3 item">
            <img src={family2} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family3} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family4} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family5} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family6} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family7} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family8} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family9} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family10} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family11} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family12} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family13} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family14} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family15} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family16} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family17} className="img-family"></img>
          </div>
          <div className="col-md-3 item">
            <img src={family18} className="img-family"></img>
          </div>
        </div> */}
      </div>
    </section>
  );
}
