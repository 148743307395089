import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../actions";

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const [service, setService] = useState([]);
  const [onGetData, setLoading] = useState(true);
  const fetchData = () => {
    fetch(apiUrl.detailService(params.id))
      .then((response) => {
        return response.json();
       
      })
      .then((data) => {
        if (data.service_detail) {
          setService(data)          
          setLoading(false)
        } else {
          const navigate = useNavigate();
          navigate("/")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 2000);
  }, []);

  return <WrappedComponent data={service} loading={onGetData} />;
};

export default withRouter;
