import React, { useState, useEffect, useRef } from "react";
import ReactInputMask from "react-input-mask";
import "react-multi-carousel/lib/styles.css";
import logo from "../assets/images/logo.png";
import imgbottom from "../assets/images/post/img-bottom.svg";
import imgtop from "../assets/images/post/img-top.svg";
import "../assets/scss/home/home.css";
import "../assets/scss/freelance/freelance.css";
import { useParams } from "react-router-dom";
import { apiUrl } from "../actions/index";
import Select from "react-select";
import Swal from "sweetalert2";
import Nav from "../parts/Nav";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import checklist from "../assets/images/icon/checklist.svg";
import { Datepicker } from '@ijavad805/react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import 'moment/locale/id'

export default function FormFreelance() {
  const inputRef = useRef(null);
  const MySwal = withReactContent(Swal);
  function formatDate(newdate) {
    const date = new Date(newdate);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }


  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#0e2c4e;" : null,
        color: isFocused ? "#ffff;" : "#333333",
      };
    },
  };

  const [rekening, setRekening] = useState([
    {
      value: "bca",
      label: "BCA",
    },
    {
      value: "mandiri",
      label: "Mandiri",
    },
  ]);
  const [provinsiKtp, setProvinsi] = useState([]);
  const [kotaKtp, setKota] = useState([
    {
      value: "sleman",
      label: "Sleman",
    },
  ]);
  const [kecamatanKtp, setKecamatan] = useState([
    {
      value: "concat",
      label: "Concat",
    },
  ]);

  const [provinsiDomisili, setProvinsiDomisli] = useState([
    {
      value: "yogyakarta",
      label: "Yogyakarta",
    },
  ]);
  const [kotaDomisili, setKotaDomisili] = useState([
    {
      value: "sleman",
      label: "Sleman",
    },
  ]);
  const [kecamatanDomisili, setKecamatanDomisili] = useState([
    {
      value: "concat",
      label: "Concat",
    },
  ]);

  const [startDate, setStartDate] = useState(null);
  const [domisili, setDomisili] = useState(true);
  const [isSubmit, setSubmit] = useState(false);
  const initialForm = {
    nama: "",
    tanggal_lahir: "",
    no_ktp: "",
    jenis_kelamin: "Laki - Laki",
    no_hp: "",
    email: "",
    nama_bank: "",
    nomor_rekening: "",
    id_provinsi: {},
    id_kabupaten: {},
    id_kecamatan: {},
    alamat: "",
    domisili_provinsi: {},
    domisili_kabupaten: {},
    domisili_kecamatan: {},
    domisili_alamat: "",
    nama_kerabat: "",
    no_hp_kerabat: "",
  };
  const [postForm, setForm] = useState(initialForm);

  const fecthBank = () => {
    fetch(`${apiUrl.bank()}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const { list } = data;
        var item = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.text,
            });
          }
          setRekening(item);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const fetchProvinsi = () => {
    fetch(`${apiUrl.provinsi()}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const { list } = data;
        var item = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.nama,
            });
          }
          setProvinsi(item);
          setProvinsiDomisli(item);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const fetchKabupaten = (id_provinsi, type) => {
    fetch(`${apiUrl.kabupaten(id_provinsi)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const { list } = data;
        var item = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.nama,
            });
          }
          if (type == 'ktp') {
            setKota(item);
            setKecamatan([]);
          } else {
            setKotaDomisili(item);
            setKecamatanDomisili([]);
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const fetchKecamatan = (id_kabupaten, type) => {
    fetch(`${apiUrl.kecamatan(id_kabupaten)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const { list } = data;
        var item = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            item.push({
              value: element.id,
              label: element.nama,
            });
          }
          if (type == 'ktp') {
            setKecamatan(item);
          } else {
            setKecamatanDomisili(item);
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    // const input = inputRef.current;
    // input.select();
    fecthBank();
    fetchProvinsi();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setForm((values) => ({ ...values, [name]: value }));
  };

  const handleChangeNotInput = (name, value) => {
    setForm((values) => ({ ...values, [name]: value }));
  }

  const currentValue = (obj) => {
    if (obj && obj.value) {
      return obj.text
    }
    return '';
  }

  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      var dataPost = {...postForm}; 
      dataPost.id_provinsi = postForm.id_provinsi.value
      dataPost.id_kabupaten = postForm.id_kabupaten.value
      dataPost.id_kecamatan = postForm.id_kecamatan.value
      if (domisili) {
        dataPost.domisili_alamat = postForm.alamat
        dataPost.domisili_provinsi = postForm.id_provinsi.value
        dataPost.domisili_kabupaten = postForm.id_kabupaten.value
        dataPost.domisili_kecamatan = postForm.id_kecamatan.value
      } else {
        dataPost.domisili_provinsi = postForm.domisili_provinsi.value
        dataPost.domisili_kabupaten = postForm.domisili_kabupaten.value
        dataPost.domisili_kecamatan = postForm.domisili_kecamatan.value
      }

      setSubmit(true);
      let res = await axios.post(
        `${apiUrl.freelance()}/save-freelance`,
        dataPost
      );
      if (res.status === 201) {
        setForm(initialForm);
        setSubmit(false);
        MySwal.fire({
          html: `
          <div><img src=${checklist} style="width: 120px;"></img></div>
          <div class="mt-3 text">
            <b>Data Freelance berhasil disimpan</b>
          </div>`,
          confirmButtonText: "Tutup",
          background: "#0E2C4E",
          color: "#fff",
          padding: "2em",
          confirmButtonColor: "#26B2C3",
          customClass: {
            confirmButton: "mt-4 button-alert",
          },
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi Kesalahan, silakan ulangi kembali nanti",
        });
      }
    } catch (err) {
      setSubmit(false);
      var errMsg = err.response.data
      var msg = 'Terjadi Kesalahan, silakan ulangi kembali nanti'
      if (Array.isArray(errMsg.msg)) {
        msg = 'Pastikan semua data terisi'
        if (errMsg.msg[0].param == 'no_ktp' && errMsg.msg[0].msg == 'Nomor KTP already in use') {
          msg = 'NIK Sudah terdaftar'
        } else if (errMsg.msg[0].param == 'email' && errMsg.msg[0].msg == 'Email already in use') {
          msg = 'Email Sudah terdaftar'
        } else if (errMsg.msg[0].param == 'no_hp' && errMsg.msg[0].msg == 'Nohp already in use') {
          msg = 'Nomor Telepon Sudah terdaftar'
        }
      }
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: msg,
      });
    }
  };

  const onChangeRadio = (ev) => {
    const value = ev.target.value;
    setForm.jenis_kelamin = value;
  };
  const userIdHandler = (event) => {
    const value = event.value;
    // console.log(event);
    // const name = event.target.name;
    // setForm((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      <div className="post-page freelance">
        <Nav></Nav>
        <img src={imgbottom} className="img-bottom"></img>
        <img src={imgtop} className="img-top"></img>
        <img src={logo} className="logo-footer"></img>
        <div className="main-content">
          <div className="container">
            <div className="content">
              <div className="title-section text-center">Form Freelance</div>
              <div className="title-form">Data Diri</div>
              <div className="subtitle-form">
                Masukkan data diri dengan benar
              </div>
              <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nama</label>
                      <input
                        name="nama"
                        value={postForm.nama}
                        type="text"
                        ref={inputRef}
                        className="form-control"
                        placeholder="Masukkan nama"
                        required
                        onChange={(e) => handleChange(e)}></input>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>NIK</label>
                      <input
                        name="no_ktp"
                        value={postForm.no_ktp}
                        type="text"
                        required
                        className="form-control"
                        placeholder="Masukkan NIK"
                        onChange={(e) => handleChange(e)}></input>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Tanggal Lahir</label>
                      <div>
                        <Datepicker 
                         input={<input placeholder="Masukkan tanggal lahir" 
                         defaultValue={null} className="form-control w-100" />} 
                         modeTheme={'dark'} format={'DD MMMM YYYY'} 
                         adjustPosition={"auto"} 
                         onChange={(val) => {
                          handleChangeNotInput('tanggal_lahir', val.format("YYYY-MM-DD"))
                          setStartDate(val._d)
                         }}
                         lang={"en"}></Datepicker>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Jenis Kelamin</label>
                      <div>
                        <label className="mr-3">
                          <input
                            type="radio"
                            name="jenis_kelamin"
                            value={"Laki - Laki"}
                            checked={postForm.jenis_kelamin === "Laki - Laki"}
                            onChange={(e) => handleChange(e)}
                          />
                          <div className="d-flex align-items-center me-3">
                            <span>Laki - laki</span>
                          </div>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="jenis_kelamin"
                            value={"Perempuan"}
                            checked={postForm.jenis_kelamin === "Perempuan"}
                            onChange={(e) => handleChange(e)}
                          />
                          <div className="d-flex align-items-center">
                            <span>Perempuan</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label>No Telepon</label>
                          <ReactInputMask mask="99999999999999999" required={true} value={postForm.no_hp} maskChar="" className="form-control" placeholder="Masukkan nomor telepon" name="no_hp" onChange={e => handleChange(e)}>
                          </ReactInputMask>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            name="email"
                            value={postForm.email}
                            type="email"
                            className="form-control"
                            placeholder="Masukkan email"
                            required
                            onChange={(e) => handleChange(e)}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Rekening</label>
                      <div className="row">
                        <div className="col-6">
                          <Select
                            options={rekening}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Pilih Rekening"
                            styles={colourStyles}
                            name="nama_bank"
                            required
                            onChange={(e) => handleChangeNotInput('nama_bank', e.value)}
                          />
                        </div>
                        <div className="col-6">
                          <ReactInputMask mask="999999999999999999999999" required={true} value={postForm.nomor_rekening} maskChar="" className="form-control" placeholder="Masukkan nomor rekening" name="nomor_rekening" onChange={e => handleChange(e)}>
                          </ReactInputMask>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="line"></div>
                <div className="title-form mt-5">Alamat Sesuai KTP</div>
                <div className="subtitle-form">
                  Masukkan alamat sesuai ktp dengan benar
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Provinsi</label>
                      <Select
                        options={provinsiKtp}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Pilih provinsi"
                        styles={colourStyles}
                        value={currentValue(postForm.id_provinsi)}
                        required
                        onChange={(e) => {
                          handleChangeNotInput('id_kabupaten', '')
                          handleChangeNotInput('id_provinsi', e)
                          handleChangeNotInput('id_kecamatan', '')
                          fetchKabupaten(e.value, 'ktp');
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Kabupaten / Kota</label>
                      <Select
                        options={kotaKtp}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Pilih kabupaten / kota"
                        styles={colourStyles}
                        value={currentValue(postForm.id_kabupaten)}
                        required
                        onChange={(e) => {
                          handleChangeNotInput('id_kabupaten', e)
                          handleChangeNotInput('id_kecamatan', '')
                          fetchKecamatan(e.value, 'ktp');
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Kecamatan</label>
                      <Select
                        options={kecamatanKtp}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Pilih kecamatan"
                        styles={colourStyles}
                        value={currentValue(postForm.id_kecamatan)}
                        required
                        onChange={(e) => {
                          handleChangeNotInput('id_kecamatan', e)
                          // fetchKecamatan(e.value, 'ktp');
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Alamat</label>
                      <textarea
                        name="alamat"
                        value={postForm.alamat}
                        className="form-control"
                        placeholder="Masukkan Alamat"
                        style={{ height: "268px" }}
                        required
                        onChange={(e) => handleChange(e)}></textarea>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <div className="title-form mt-5">
                      Alamat Sesuai Domisili
                    </div>
                    <div className="subtitle-form">
                      Masukkan alamat sesuai ktp dengan benar
                    </div>
                  </div>
                  <div className="subtitle-form d-flex align-items-center">

                    <input
                      type="checkbox"
                      className=""
                      checked={domisili}
                      onChange={(e) => setDomisili(!domisili)}></input>
                    Alamat domisili sesuai alamat ktp
                  </div>
                </div>

                {!domisili && (
                  <div className="row mt-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Provinsi</label>
                        <Select
                          options={provinsiDomisili}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Pilih provinsi"
                          styles={colourStyles}
                          required={domisili}
                          value={currentValue(postForm.domisili_provinsi)}
                          onChange={(e) => {
                            handleChangeNotInput('domisili_provinsi', e)
                            handleChangeNotInput('domisili_kabupaten', '')
                            handleChangeNotInput('domisili_kecamatan', '')
                            fetchKabupaten(e.value, 'domisili');
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Kabupaten / Kota</label>
                        <Select
                          options={kotaDomisili}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Pilih kabupaten / kota"
                          styles={colourStyles}
                          required={domisili}
                          value={currentValue(postForm.domisili_kabupaten)}
                          onChange={(e) => {
                            handleChangeNotInput('domisili_kabupaten', e)
                            handleChangeNotInput('domisili_kecamatan', '')
                            fetchKecamatan(e.value, 'domisili');
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Kecamatan</label>
                        <Select
                          options={kecamatanDomisili}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Pilih kecamatan"
                          styles={colourStyles}
                          required={domisili}
                          value={currentValue(postForm.domisili_kecamatan)}
                          onChange={(e) => {
                            handleChangeNotInput('domisili_kecamatan', e)
                            // fetchKecamatan(e.value, 'ktp');
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Alamat</label>
                        <textarea
                          value={postForm.domisili_alamat}
                          className="form-control"
                          placeholder="Masukkan Alamat"
                          name="domisili_alamat"
                          style={{ height: "268px" }}
                          required={domisili}
                          onChange={(e) => handleChange(e)}></textarea>
                      </div>
                    </div>
                  </div>
                )}

                <div className="line"></div>
                <div className="title-form mt-5">Kontak Darurat</div>
                <div className="subtitle-form">
                  Masukkan data kontak darurat dengan benar
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nama Kerabat</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Masukkan nama kerabat"
                        required
                        name="nama_kerabat"
                        onChange={(e) => handleChange(e)}></input>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nomor Telepon Kerabat</label>
                      <ReactInputMask mask="99999999999999999" required={true} value={postForm.no_hp_kerabat} maskChar="" className="form-control" placeholder="Masukkan nomor telepon kerabat" name="no_hp_kerabat" onChange={e => handleChange(e)}>
                      </ReactInputMask>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button className="btn btn-primary" disabled={isSubmit} type="submit">
                    {isSubmit ? 'Loading...' : 'Simpan'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>COPYRIGHT PT. JAWARA KREASITAMA</div>
          <div>ILLUSTRATION BY PACULPORA | WEBSITE BY ABCD SOFTWARE HOUSE </div>
        </div>
      </div>
    </>
  );
}
