import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import Navbar from "./helpers/Navbar";
import Home from "./pages/Home";
import Portofolio from "./pages/Portofolio";
import Blog from "./pages/Blog/DetailBlog";
import MoreBlog from "./pages/Blog/MoreBlog";
import Service from "./pages/Service";
import Post from "./pages/DetailPost";
import MorePortofolio from "./pages/MorePortofolio";
import MoreNews from "./pages/News/MoreNews";
import DetailNews from "./pages/News/DetailNews";
import MoreDownload from "./pages/MoreDownload";
import FormFreelance from "./pages/FormFreelance";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";

function setLocalStorageWithExpiry(key, value, hours) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + hours * 60 * 60 * 1000, // Calculate expiry time
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// Function to get data from localStorage and check for expiry
function getLocalStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key); // Remove the item if it's expired
    return null;
  }
  return item.value;
}

export default function App() {
  const [loading, setLoading] = useState(getLocalStorageWithExpiry('_fkaA') ? false : true);
  useEffect(() => {
    // setLoading(true);
    if (!getLocalStorageWithExpiry('_fkaA', '1')) {
      setLocalStorageWithExpiry('_fkaA', '1', 1);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, []);
  return (
    <div>
      {loading ? (
        <div className="loader">
          <ScaleLoader color={"#fff"} loading={loading} size={80} />
        </div>
      ) : (
        <Router>
          <div className="App">
            <Navbar></Navbar>
            <Routes>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="/portofolio/:id" element={<Portofolio />} />
              <Route path="/portofolio" element={<MorePortofolio />} />
              <Route path="/service/:id" element={<Service />} />
              <Route path="/blog" element={<MoreBlog />} />
              <Route path="/blog/:id" element={<Blog />} />
              <Route path="/download" element={<MoreDownload />} />
              <Route path="/news" element={<MoreNews />} />
              <Route path="/freelance" element={<FormFreelance />} />
              <Route path="/news/:id" element={<DetailNews />} />
              <Route path="/post/:id" element={<Post />} />
            </Routes>
          </div>
        </Router>
      )}
    </div>
  );
}
