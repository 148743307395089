import React, { useEffect, useState } from "react";
import "../assets/scss/timeline/timeline.css";
import bg from "../assets/images/timeline/bg.png";
import daunatas from "../assets/images/timeline/daunatas.svg";
import gatotkaca from "../assets/images/timeline/gatotkaca.svg";
import gunung from "../assets/images/timeline/gunung.svg";
import kotakanan from "../assets/images/timeline/kota-kanan.svg";
import kotakiri from "../assets/images/timeline/kota-kiri.svg";
import timelineFront from "../assets/images/timeline/timeline_front.svg";
import timelineBack from "../assets/images/timeline/timeline_back.svg";
import timelinemobile from "../assets/images/timeline/timeline-mobile.svg";
import pandemi from "../assets/images/timeline/pandemi.svg";

import gif1 from "../assets/images/timeline/gif/gif-1.gif";
import gif2 from "../assets/images/timeline/gif/gif-2.gif";
import gif3 from "../assets/images/timeline/gif/gif-3.gif";
import gif4 from "../assets/images/timeline/gif/gif-4.gif";
import gif5 from "../assets/images/timeline/gif/gif-5.gif";

import particle1 from "../assets/images/timeline/particle-1.svg";
import particle2 from "../assets/images/timeline/particle-2.svg";
import particle5 from "../assets/images/timeline/particle-5.svg";
import particle7 from "../assets/images/timeline/particle-7.svg";
import particle4 from "../assets/images/timeline/particle-4.svg";
import particle6 from "../assets/images/timeline/particle-6.svg";

import particleTimeline1 from "../assets/images/timeline/particle-timeline-1.svg";
import particleTimeline2 from "../assets/images/timeline/particle-timeline-2.svg";
import particleTimeline3 from "../assets/images/timeline/particle-timeline-3.svg";
import particleTimeline4 from "../assets/images/timeline/particle-timeline-4.svg";
import particleTimeline5 from "../assets/images/timeline/particle-timeline-5.svg";
import { apiUrl, dataUrl } from "../actions/index";

export default function Timeline({data}) {
  const [bgtimeline, setTimeline] = useState([]);
  // const fetchData = () => {
  //   fetch(`${apiUrl.home()}`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTimeline(data.year);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  // const fetchData = () => {
  //   fetch(process.env.REACT_APP_API_URL+"/home")
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       const strDescending = [...data.year].sort((a, b) =>
  //         a.years > b.years ? -1 : 1
  //       );
  //       setYears(strDescending);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };
  useEffect(() => {
    setTimeline(data);
  }, [data]);
  return (
    <section className="timeline" id="section-timeline">
      <div className="timeline1">
        <img src={bg} className="img-asset"></img>
        {/* <img src={gatotkaca} className="img-asset"></img>
      <img src={kotakanan} className="img-asset"></img>
      <img src={kotakiri} className="img-asset"></img> */}
        <img src={timelineFront} className="img-asset"></img>
        <img src={timelineBack} className="back-img"></img>
        <img src={timelinemobile} className="timeline-mobile"></img>

        <img src={gif1} className="gif gif-1"></img>
        <img src={gif2} className="gif gif-2"></img>
        <img src={gif3} className="gif gif-3"></img>
        <img src={gif4} className="gif gif-4"></img>
        <img src={gif5} className="gif gif-5"></img>
        {/* <img src={pandemi} className="pandemi"></img> */}

        <div className="wrapper-particle">
          <img src={particle2} className="particle-2 particle-timeline"></img>
          <img src={particle5} className="particle-5 particle-timeline"></img>
          <img src={particle7} className="particle-7 particle-timeline"></img>
          <img src={particle4} className="particle-4 particle-timeline"></img>
          <img src={particle6} className="particle-6 particle-timeline"></img>
          <img
            src={particleTimeline1}
            className="particle-timeline-1 particle-timeline"></img>
          <img
            src={particleTimeline2}
            className="particle-timeline-2 particle-timeline"></img>
          <img
            src={particleTimeline3}
            className="particle-timeline-3 particle-timeline"></img>
          <img
            src={particleTimeline4}
            className="particle-timeline-4 particle-timeline"></img>
          <img
            src={particleTimeline5}
            className="particle-timeline-5 particle-timeline"></img>
        </div>
        <img src={bgtimeline.year_summary ? dataUrl.year()+bgtimeline.year_summary : '#'} className="img-timeline"></img>

        <div className="wrapper-timeline">
          <div>
            {/* {years.map((item, index) => (
              <div className="item-timeline" key={`item-${index}`}>
                <div className="years">{item.years}</div>
                <div className="events">{item.events} Events</div>
                <div className="clients">{item.clients} Clients</div>
              </div>
            ))} */}

            {/* <div className="item-timeline">
              <div className="years">2021</div>
              <div className="events">192 Events</div>
              <div className="clients">35 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2020</div>
              <div className="events">167 Events</div>
              <div className="clients">25 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2019</div>
              <div className="events">275 Events</div>
              <div className="clients">92 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2018</div>
              <div className="events">259 Events</div>
              <div className="clients">90 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2017</div>
              <div className="events">240 Events</div>
              <div className="clients">50 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2016</div>
              <div className="events">171 Events</div>
              <div className="clients">69 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2015</div>
              <div className="events">118 Events</div>
              <div className="clients">50 Clients</div>
            </div>

            <div className="item-timeline">
              <div className="years">2014</div>
              <div className="events">125 Events</div>
              <div className="clients">53 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2013</div>
              <div className="events">112 Events</div>
              <div className="clients">49 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2012</div>
              <div className="events">117 Events</div>
              <div className="clients">56 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2011</div>
              <div className="events">107 Events</div>
              <div className="clients">49 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2010</div>
              <div className="events">137 Events</div>
              <div className="clients">56 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2009</div>
              <div className="events">131 Events</div>
              <div className="clients">69 Clients</div>
            </div>
            <div className="item-timeline">
              <div className="years">2008</div>
              <div className="events">131 Events</div>
              <div className="clients">69 Clients</div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
