import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import imgporto1 from "../assets/images/post/img-porto-1.jpg";
import imgporto2 from "../assets/images/post/img-porto-2.jpg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import "../assets/scss/portofolio/portofolio.css";
import "../assets/scss/portofolio/portofolio.css";
import { apiUrl, dataUrl } from "../actions/index";

function Lightbox() {
  const urlHttps = (url) => {
    try {
      var urlObj = new URL(url);
      var videoId = urlObj.searchParams.get("v");

      if (!videoId) {
        // If the search param 'v' is not found, try parsing the pathname
        var pathnameParts = urlObj.pathname.split("/");
        videoId = pathnameParts[pathnameParts.length - 1];
      }

      return videoId;
    } catch (error) {
      // Invalid URL
      return null;
    }
  };
  const params = useParams();
  const [listData, setData] = useState([]);
  const [listImg, setImg] = useState([]);
  const [video, setVideo] = useState([]);
  const [onGetData, setLoading] = useState(true);

  const fetchData = () => {
    fetch(`${apiUrl.detialPortofolio(params.id)}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const result = data.portofolio_gambar;
        const dataVideo = result.find((e) => e.tipe == "video");

        var dataImage = result.filter(function (obj) {
          return obj["tipe"] === "gambar";
        });

        var fileImg = dataImage.map(function (element, index) {
          var file = `${dataUrl.experience()}` + element.file;
          return file;
        });
        setData(dataImage);
        setImg(fileImg);
        setVideo(dataVideo && dataVideo.length > 0 ? [dataVideo] : []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, []);
  }, []);
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      centerMode: true,
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      centerMode: true,
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      centerMode: false,
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const showData = () => {
    return (
      <>
        {listData.length === 0 ? (
          ""
        ) : listData.length === 1 ? (
          <div>
            {listData.map((item, index) => (
              <div className="card">
                <img
                  key={`item-${index}`}
                  className="product--image"
                  onClick={() => openLightboxOnSlide(index + 1)}
                  src={`${dataUrl.experience()}/${item.file}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <Carousel
            centerMode
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            className=""
            dotListClass=""
            draggable
            focusOnSelect={true}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable>
            {
            video.length < 0 || listData.length > 0 ?
            listData.map((item, index) => (
              <div className="card" key={`item-${index}`}>
                <img
                  className="product--image"
                  onClick={() => openLightboxOnSlide(index + 1)}
                  src={`${dataUrl.experience()}/${item.file}`}
                />
              </div>
            ))
          :
          video.map((item, index) => (
            <div className="card" key={`item-${index}`}>
              <iframe
                width="100%"
                height="480"
                src={`https://www.youtube.com/embed/${urlHttps(item.file)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          ))
          
          }
            {/* {video.length > 0
              ? video.map((item, index) => (
                  <div className="card" key={`item-${index}`}>
                    <iframe
                      width="100%"
                      height="480"
                      src={`https://www.youtube.com/embed/${urlHttps(item.file)}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </div>
                ))
              :
              <></>
              } */}

            {/* <div className="card">
           <img
             className="product--image"
             onClick={() => openLightboxOnSlide(2)}
             src={imgporto1}
           />
         </div>
         <div className="card">
           <img
             className="product--image"
             onClick={() => openLightboxOnSlide(1)}
             src={imgporto2}
           />
         </div> */}
          </Carousel>
        )}
      </>
    );
  };
  const showSkeleton = () => {
    return (
      <>
        <Carousel
          centerMode
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          className=""
          dotListClass=""
          draggable
          focusOnSelect={true}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable>
          <SkeletonTheme color="grey" highlightColor="#bfbfbf">
            <div className="card" style={{ height: "400px" }}>
              <Skeleton height={400} style={{ lineHeight: "unset" }} />
            </div>
          </SkeletonTheme>
        </Carousel>
      </>
    );
  };
  return (
    <>
      <div className=" carousel">
        <div className="carousel-dekstop">
          {onGetData ? showSkeleton() : showData()}
        </div>
      </div>
      <FsLightbox
        onOpen={(e) => {
          document.querySelector(".nav").style.display = "none";
        }}
        onClose={(e) => {
          document.querySelector(".nav").style.display = "block";
        }}
        toggler={lightboxController.toggler}
        sources={listImg}
        slide={lightboxController.slide}
      />
    </>
  );
}

export default Lightbox;
